export class NotificationSettings {
  colorBoards: boolean
  projects: boolean
  newTask: boolean
  completedTask: boolean
  taskReminder: boolean
  taskReminderPeriod: number
  areaComments: boolean
  noteComments: boolean
}
