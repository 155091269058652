import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {map, Observable} from "rxjs";
import {apiUrl} from "../../environments/environment";
import {Project} from "../_models/project";
import {Page} from "../_models/page";
import {ProjectTable} from "../_models/project-table";
import {FloorDuplicateMenu} from "../_models/floor";
import {SubRole, User} from "../_models/user";
import {ProjectProgressUserSettings} from "../_models/projectProgressUserSettings";
import {convertSubRoleToEnum, convertSystemRoleToEnum} from "../_util/utils";

@Injectable({
  providedIn: 'root'
})
export class ProjectsService {

  constructor(private http: HttpClient) {
  }


  getProjectListByParams(companyId: number, hideCompleted: boolean, companyName: string, clientName: string, query: string, sortField: string, sortDirection: string, page: number, pageSize: number): Observable<Page<Project>> {
    let params = new HttpParams();
    if (companyId) params = params.set('companyId', companyId);
    if (companyName) params = params.set('companyName', companyName);
    if (hideCompleted) params = params.set('hideCompleted', hideCompleted);
    if (clientName) params = params.set('clientName', clientName);
    if (query && query.length > 0) params = params.set('query', query);
    if (sortField) params = params.set('sortField', sortField);
    if (sortDirection) params = params.set('sortDirection', sortDirection);
    if (page) params = params.set('page', page);
    if (pageSize) params = params.set('pageSize', pageSize);

    return this.http.get<Page<Project>>(`${apiUrl}/project/list`, {
      params: params,
      withCredentials: true
    })
  }

  getProjectShortList(companyId: number): Observable<Project[]> {
    return this.http.get<Project[]>(`${apiUrl}/project/short`, {params: {companyId: companyId}, withCredentials: true})
  }

  getProjectAccess(projectId: number): Observable<SubRole> {
    return this.http.get<SubRole>(`${apiUrl}/project/${projectId}/access`, {withCredentials: true}).pipe(map(subRole => {
      return convertSubRoleToEnum(subRole)
    }))
  }

  export(companyId: number, hideCompleted: boolean, companyName: string, clientName: string, query: string, sortField: string, sortDirection: string) {
    let params = new HttpParams();
    if (companyId) params = params.set('companyId', companyId);
    if (companyName) params = params.set('companyName', companyName);
    if (hideCompleted) params = params.set('hideCompleted', hideCompleted);
    if (clientName) params = params.set('clientName', clientName);
    if (query && query.length > 0) params = params.set('query', query);
    if (sortField) params = params.set('sortField', sortField);
    if (sortDirection) params = params.set('sortDirection', sortDirection);

    return this.http.get(`${apiUrl}/project/export`, {
      params: params,
      withCredentials: true,
      responseType: 'blob',
      observe: 'response'
    })
  }

  getAllProjectsShortList(): Observable<Project[]> {
    return this.http.get<Project[]>(`${apiUrl}/project/short`, {withCredentials: true})
  }

  getProjectById(projectId: number): Observable<Project> {
    return this.http.get<Project>(`${apiUrl}/project/${projectId}`, {withCredentials: true})
  }

  getProjectProgressById(projectId: number): Observable<number> {
    return this.http.get<number>(`${apiUrl}/project/${projectId}/progress`, {withCredentials: true})
  }

  createProject(companyId: number, project: Project): Observable<Project> {
    return this.http.post<Project>(`${apiUrl}/project`, project, {params: {companyId}, withCredentials: true})
  }

  createProjectKey(projectName: string): Observable<any> {
    return this.http.post(`${apiUrl}/project/key/generateKey`, projectName, {withCredentials: true})
  }

  validateProjectKey(projectCode: string): Observable<{error: string}> {
    let params = new HttpParams().append('projectKey', projectCode)
    return this.http.get<{error: string}>(`${apiUrl}/project/key/validate`, {withCredentials: true, params: params})
  }

  updateProject(project: Project): Observable<Project> {
    return this.http.put<Project>(`${apiUrl}/project`, project, {withCredentials: true})
  }

  deleteProjects(projectIds: number[]) {
    let params = new HttpParams();
    projectIds.forEach(e => params = params.append('projectIds', e));

    return this.http.delete(`${apiUrl}/project`, {
      params: params,
      observe: "response",
      withCredentials: true
    })
  }

  assignEmployeesToProject(projectId: number, employeeIds: number[], subRole: SubRole) {
    return this.http.post(`${apiUrl}/project/${projectId}/employees`, employeeIds, {
      params: {subRole},
      observe: "response",
      withCredentials: true
    })
  }

  removeEmployeesFromProject(projectId: number, employeeIds: number[]) {
    return this.http.delete(`${apiUrl}/project/${projectId}/employees`, {
      params: {employeeIds: employeeIds},
      observe: "response",
      withCredentials: true
    }
    )
  }

  updateEmployeeProjectRole(projectId: number, employeeId: number, subRole: SubRole) {
    return this.http.put(`${apiUrl}/project/${projectId}/employee`, null, {
      params: {subRole, employeeId},
      observe: "response",
      withCredentials: true
    })
  }

  getProjectProgressTable(projectId: number): Observable<ProjectTable> {
    return this.http.get<ProjectTable>(`${apiUrl}/project/${projectId}/table`, {withCredentials: true})
  }

  updateRoomsOrderProjectProgressTable(projectId: number, headMap: Map<string, number>, tableVersion: number) {
    return this.http.put<ProjectTable>(`${apiUrl}/project/${projectId}/table/rooms/order`, Object.fromEntries(headMap), {params: {tableVersion}, withCredentials: true})
  }

  updateAreasOrderProjectProgressTable(projectId: number, headMap: Map<string, number[]>, tableVersion: number) {
    return this.http.put<ProjectTable>(`${apiUrl}/project/${projectId}/table/areas/order`, Object.fromEntries(headMap), {params: {tableVersion}, withCredentials: true})
  }

  getUnitsListInProject(projectId: number) {
    return this.http.get<FloorDuplicateMenu[]>(`${apiUrl}/project/${projectId}/units/short`, {withCredentials: true})
  }

  getProjectMentionUsers(projectId: number, username: string): Observable<User[]> {
    return this.http.get<User[]>(`${apiUrl}/project/${projectId}/employee/mention`, {params: {username}, withCredentials: true})
  }

  getProjectProgressSettings(projectId: number) {
    return this.http.get<ProjectProgressUserSettings>(`${apiUrl}/project/${projectId}/projectProgressSettings`, {withCredentials: true})
  }

  updateProjectProgressSettings(projectId: number, userSettings: ProjectProgressUserSettings) {
    return this.http.put<ProjectProgressUserSettings>(`${apiUrl}/project/${projectId}/projectProgressSettings`, userSettings, {withCredentials: true})
  }
}
