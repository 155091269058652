import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {map, Observable, switchMap} from "rxjs";
import {User} from "../_models/user";
import {Router} from "@angular/router";
import {GlobalStore} from "../global.store";
import {UsersService} from "./users.service";
import {convertSystemRoleToEnum} from "../_util/utils";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private isLoggingOut = false;

  constructor(private http: HttpClient,
              private router: Router,
              readonly globalStore: GlobalStore,
              private userService: UsersService) { }

  getCurrentUser(): User {
    const user = JSON.parse(localStorage.getItem('user')) as User;

    if(user) return user;
    return null
  }

  login(loginData: any): Observable<any> {
    let formData = new FormData();
    formData.append("email", loginData.email);
    formData.append("password", loginData.password);
    formData.append("remember_me", loginData.remember_me);
    return this.http.post<User>(`${environment.serverUrl}/login`, formData, {withCredentials: true}).pipe(
      map((user) => {
        user.systemRole = convertSystemRoleToEnum(user.systemRole);
        this.globalStore.updateCurrentUser(user);
        return user;
    }));
  }

  logout(redirectBackAfterLogin = false) {
    if (this.isLoggingOut || this.router.url.startsWith('/login')) return;
    this.isLoggingOut = true;

    this.makeLogoutRequest().subscribe(() => {
      this.globalStore.updateCurrentUser(null);

      if (redirectBackAfterLogin) this.router.navigateByUrl(`/login?returnUrl=${redirectBackAfterLogin ? this.router.url : ''}`);
      else this.router.navigateByUrl(`/login`);

      this.isLoggingOut = false;
    })
  }

  makeLogoutRequest(){
    return this.http.post(`${environment.serverUrl}/logout`, '', {observe: "response", withCredentials: true});
  }
}
