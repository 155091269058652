import {Project} from "./project";
import {Board} from "../components/admin/components/colorboard/models";

export class Company {
  id: number;
  name: string;
  subdomain: string;
  address1: string;
  address2: string;
  state: string;
  city: string;
  zipCode: string;
  email: string;
  countryCode: string;
  phoneNumber: string;
  users: string;
  type: CompanyType;
  usersCount: number;
  projects: Project[]= [];
  colorBoards: Board[]= [];
  version: number;
  companyPlan: CompanyPlan;
  projectsCount: number;
}

export enum CompanyType {
  PAINTING="PAINTING",
  FLOORING="FLOORING",
  ELECTRICAL="ELECTRICAL",
  PLUMBING="PLUMBING",
  TILING="TILING",
  HOTEL="HOTEL",
  GENERAL_CONTRACTOR="GENERAL_CONTRACTOR"
}

export class CompanyPlan {
  id: number;
  planName: string;
  maxUsers: number;
}


export class Country {
  name: string;
  dial_code: string;
  code: string;
}
