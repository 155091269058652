import { Injectable } from '@angular/core';
import { HttpRequest, HttpClient, HttpEvent } from '@angular/common/http';
import { Subject } from 'rxjs';
import {NotificationService} from "./notification.service";

interface QueuedRequest {
  request: HttpRequest<any>;
  subject: Subject<HttpEvent<any>>;
}

@Injectable({
  providedIn: 'root'
})
export class OfflineQueueService {
  private requestQueue: QueuedRequest[] = [];

  constructor(private http: HttpClient, private notificationService: NotificationService) {
    this.notificationService.newConnectionStatus.subscribe(isOnline => {
      if (isOnline) {
        this.retryRequests();
      }
    });
  }

  queueRequest(req: HttpRequest<any>, subject: Subject<HttpEvent<any>>): void {
    this.requestQueue.push({ request: req, subject });
  }

  retryRequests(): void {
    const queuedRequests = [...this.requestQueue];
    this.requestQueue = []; // очищаем очередь до повторной отправки
    queuedRequests.forEach(qr => {
      this.http.request(qr.request).subscribe(
        event => {
          qr.subject.next(event);
        },
        err => {
          qr.subject.error(err);
        },
        () => {
          qr.subject.complete();
        }
      );
    });
  }
}
