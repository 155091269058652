import {Company} from "./company";
import {Project} from "./project";
import {Board} from "../components/admin/components/colorboard/models";

export class User {
  id: number;
  name: string;
  systemRole: SystemRole;
  email: string;
  countryCode: string;
  phone: string;
  company: Company;
  version: number;
  projects: ProjectUser[];
  boards: BoardUser[];

  projectRole: SubRole;
  projectId: number;
  boardRole: SubRole;
  boardPublicId: string;
}

export enum SystemRole {
  ROLE_SUPER_ADMIN = "ROLE_SUPER_ADMIN",
  ROLE_COMPANY_ADMIN = "ROLE_COMPANY_ADMIN",
  ROLE_USER = "ROLE_USER",
}

export enum SubRole {
  MANAGER = "MANAGER",
  MEMBER = "MEMBER",
  VIEWER = "VIEWER",
}

export class UserWithSubRole {
  user: User;
  systemRole: SystemRole;
  boardRole: SubRole;
  projectRole: SubRole;
}

export class InviteUser {
  email: string;
  companyId: number;
  projectIds: number[]; //Then delete
  systemRole: SystemRole;
  projectRole: SubRole;
  boardRole: SubRole;
  projectId: number;
  boardId: number;
}

export class UserLocation {
  country_name: string;
  country_code2: string;
}

export class ProjectUser {
  project: Project
  role: SubRole;
}

export class BoardUser {
  board: Board;
  role: SubRole;
}
