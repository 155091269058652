import {AbstractControl, FormControl, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";

export function matchValidator(matchTo: string, reverse?: boolean): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.parent && reverse) {
      const c = (control.parent?.controls as any)[matchTo] as AbstractControl;
      if (c) c.updateValueAndValidity();

      return null;
    }
    return !!control.parent && !!control.parent.value && control.value === (control.parent?.controls as any)[matchTo].value ? null : { error: 'Passwords don\'t match' };
  };
}

export function startDateEndDateValidator(otherDate: string, isThisStartDate?: boolean): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.parent) {
      const otherDateControl = (control.parent?.controls as any)[otherDate] as AbstractControl;
      if (otherDateControl?.value && control.value) {
        let startDate = isThisStartDate ? new Date(control.value).getTime() : new Date(otherDateControl.value).getTime();
        let endDate = isThisStartDate ? new Date(otherDateControl.value).getTime() : new Date(control.value).getTime();

        if (startDate <= endDate) {
          otherDateControl.setErrors(null);
          return null;
        }
        else return {datesWrong: true};
      } else {
        otherDateControl.setErrors(null);
        return null;
      }
    } return null;
  };
}

export function etaDateValidator(etaDateControlName: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.parent) {
      const etaDateControl = (control.parent?.controls as any)[etaDateControlName] as AbstractControl;
      if (etaDateControl?.value && control.value) {
        const etaDate = new Date(etaDateControl.value).setHours(0, 0, 0, 0);
        const today = new Date().setHours(0, 0, 0, 0);
        if (etaDate >= today) {
          etaDateControl.setErrors(null);
          return null;
        }
        else return {datesWrong: true};
      } else {
        etaDateControl.setErrors(null);
        return null;
      }
    } return null;
  };
}

export function emailValidatorByPattern(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value && control.value.match("^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")) return null;
    else return {error: FORM_ERRORS.EMAIL.PATTERN_ERROR}
  };
}

export function requiredValidator() {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value || control.value.length === 0) return {error: 'This field is required'};
    else return null;
  };
}

export function minLengthValidator(fieldName: 'PASSWORD' | 'NAME' | 'LINK_NAME' | 'FLOOR_UNIT_NAME' | 'CUSTOM_PLAN_MAX_USERS' | 'ROOM_AREA_NAME' | 'ZIP_CODE' | 'PHONE' | 'ADDRESS') {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value || control.value.length < FORM_ERRORS[fieldName].MIN_LENGTH.VALUE) return {error: FORM_ERRORS[fieldName].MIN_LENGTH.MESSAGE};
    else return null;
  };
}


export const FORM_ERRORS = {
  EMAIL: {
    PATTERN_ERROR: "Invalid email format. Please use the format example@example.com.",
    MAX_LENGTH_VALUE: 50
  },
  PASSWORD: {
    MIN_LENGTH: {
      VALUE: 6,
      MESSAGE: "Password must be at least 6 characters long."
    },
    MAX_LENGTH_VALUE: 40
  },
  NAME: {
    MIN_LENGTH: {
      VALUE: 2,
      MESSAGE: "Name must be at least 2 characters long."
    },
    MAX_LENGTH_VALUE: 30
  },
  BOARD_NOTE_NAME: {
    MIN_LENGTH: {
      VALUE: 2,
      MESSAGE: "Name must be at least 2 characters long."
    },
    MAX_LENGTH_VALUE: 50
  },
  LINK_NAME: {
    MIN_LENGTH: {
      VALUE: 2,
      MESSAGE: "Name must be at least 2 characters long."
    },
    MAX_LENGTH_VALUE: 50
  },
  FLOOR_UNIT_NAME: {
    MIN_LENGTH: {
      VALUE: 1,
      MESSAGE: "Name must be at least 1 characters long."
    },
    MAX_LENGTH_VALUE: 15
  },
  ROOM_AREA_NAME: {
    MIN_LENGTH: {
      VALUE: 1,
      MESSAGE: "Name must be at least 1 characters long."
    },
    MAX_LENGTH_VALUE: 30
  },
  ADDRESS: {
    MIN_LENGTH: {
      VALUE: 5,
      MESSAGE: "Address must be at least 5 characters long."
    },
    MAX_LENGTH_VALUE: 50
  },
  ZIP_CODE: {
    MIN_LENGTH: {
      VALUE: 5,
      MESSAGE: "ZIP code should be 5 digits long."
    },
    MAX_LENGTH_VALUE: 5
  },
  PHONE: {
    MIN_LENGTH: {
      VALUE: 10,
      MESSAGE: "Phone number must be 10 digits long."
    },
    MAX_LENGTH_VALUE: 10
  },
  CUSTOM_PLAN_MAX_USERS: {
    MIN_LENGTH: {
      VALUE: 1,
      MESSAGE: "Number of users should be at least 1 digits long."
    },
    MAX_LENGTH_VALUE: 6
  },
  PROJECT_CODE: {
    MAX_LENGTH_VALUE: 4
  },
  PROJECT_NUMBER: {
    MAX_LENGTH_VALUE: 50
  },
  TASK_DESCRIPTION: {
    MAX_LENGTH_VALUE: 200
  }
}
