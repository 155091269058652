<header class="sub-header" [attr.isDashboard]="isDashboard">
  <div class="subheader-inner-wrapper">
    <h1 class="sub-header__title">
      <ng-container *ngFor="let item of title">
        <span *ngIf="item?.length > 0" [ngClass]="title.length === 1 ? 'sub-header__title--big' : ''">{{item}}</span>
      </ng-container>
    </h1>

    <div class="mobile-menu-wrapper" (click)="openSidebar()">
      <svg class="mobile-menu-icon">
        <use spriteVersion iconName="mobile-menu"></use>
      </svg>
      Menu
    </div>

    <div class="notifications-with-profile-wrapper">
      <div class="notifications-wrapper" (appOutsideClick)="isOpenNotifications = false; markAsReadNotif()" [enabled]="isOpenNotifications">
        <button type="button" class="notifications-btn" (click)="isOpenNotifications = !isOpenNotifications; filterNotifications()">
          <svg class="bell-notif-icon">
            <use spriteVersion iconName="bell"></use>
          </svg>
          <div class="notifications-amount"><span>{{notificationService.unreadNotifCount}}</span></div>
        </button>

        <div class="notifications-list-wrapper" [attr.show]="isOpenNotifications">
          <div class="notifications-list-title">
            Notifications
            <div class="notifications-list__showRead-with-close-wrapper">
              <div class="notifications-list__showRead-wrapper">
                Only show unread

                <ng-toggle
                  [value]="isSelectUnreadNotif"
                  [color]="{unchecked: '#D8D8D8', checked: '#37A702'}"
                  (change)="this.isSelectUnreadNotif = !isSelectUnreadNotif; filterNotifications();"
                ></ng-toggle>

                <span *ngIf="user.systemRole !== SystemRole.ROLE_SUPER_ADMIN" #dotsSettings class="dots-notif-settings" (click)="dotsSettings.setAttribute('open', 'true')"
                      (appOutsideClick)="dotsSettings.removeAttribute('open')" [enabled]="dotsSettings.hasAttribute('open')">
                  <ul class="notif-settings-menu">
                    <li class="notif-settings-item" (click)="dotsSettings.removeAttribute('open'); isOpenNotifications=false; isNotifSettingsOpen=true">
                      <svg class="notif-icon-settings">
                        <use spriteVersion iconName="settings"></use>
                      </svg>
                      Settings
                    </li>
                  </ul>
                </span>
              </div>
              <svg class="close-notif-icon" (click)="isOpenNotifications = false">
                <use spriteVersion iconName="close-notif"></use>
              </svg>
            </div>
          </div>
          <div class="notifications-list-wrapper-with-padding">
            <ul #notificationContainer class="notifications-list">
              <li class="notifications-list__not-found" *ngIf="notificationService.notificationsList.length === 0 || filteredNotificationsList.length === 0">
                <svg class="notifications-mock">
                  <use spriteVersion iconName="notifications-mock"></use>
                </svg>
                {{notificationService.notificationsList.length === 0 && !isSelectUnreadNotif? 'No notifications found' : 'No unread notifications' }}
              </li>
              <li class="notifications-list__item" (click)="router.navigateByUrl(notification.link); isOpenNotifications = false;  markAsReadNotif() " [attr.read]="!notification.delivered"
                  *ngFor="let notification of filteredNotificationsList; let idx = index">
                <p (visible)="notifVisible(notification, idx)"></p>
                <div class="innerHTML-notif" [innerHTML]="notification.text"></div>
                <div class="notif-info-wrapper">
                  <span class="public-id" *ngIf="notification.objectId">{{notification.objectId}}</span>
                  <span class="notif-info-time">{{notification.createdAt | timePrettify}}</span>
                </div>
              </li>
            </ul>
          </div>
        </div>

      </div>
      <div class="btn-vs-menu-wrapper" [enabled]="isOpenMenu" (appOutsideClick)="isOpenMenu = false">
        <button type="button" class="profile-user-btn" id="profileUserBtn" (click)="openProfileDropDown()" >
          <img class="user-img" [src]="userImg" alt="user avatar" *ngIf="userImg; else defaultAvatar">

          <ng-template #defaultAvatar>
            <svg class="default-avatar-icon">
              <use spriteVersion iconName="user-profile-colored"></use>
            </svg>
          </ng-template>

          {{user.name}}
          <svg class="arrow-icon">
            <use [attr.href]="isOpenMenu ? 'assets/sprite.svg#arrow-up' : 'assets/sprite.svg#arrow-down'"></use>
          </svg>
        </button>
        <div class="profile-menu"  [attr.visible]="isOpenMenu">
          <ul class="profile-menu__list">
            <li class="profile-menu__item">
              <a id="myProfileLink" [routerLink]="user.systemRole === SystemRole.ROLE_SUPER_ADMIN  ? ['/profile'] : ['/company', user.company.id, 'profile']" [routerLinkActiveOptions]="{ exact: true }" class="profile-menu__link" routerLinkActive="">
                <svg class="user-profile-icon">
                  <use spriteVersion iconName="users"></use>
                </svg>
                My Profile
              </a>
            </li>
            <li class="profile-menu__item">
              <button id="btnLogout" (click)="authService.logout(); notificationService.notificationsList = []" class="profile-menu__btn-logout">
                <svg class="user-logout-icon">
                  <use spriteVersion iconName="logout"></use>
                </svg>
                Log out
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <h1 class="mobile-subheader-title">
    <ng-container *ngFor="let item of title">
      <span *ngIf="item?.length > 0" [ngClass]="title.length === 1 ? 'sub-header__title--big' : ''">{{item}}</span>
    </ng-container>
  </h1>
</header>

<app-email-notification-settings *ngIf="isNotifSettingsOpen" (close)="isNotifSettingsOpen = false" [currentUser]="user"></app-email-notification-settings>
