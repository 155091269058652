<div class="backdrop">
    <ng-scrollbar class="remove-overflow-on-tablet modal">
      <div class="email-notif-settings-wrapper">
        <button type="button" id="btnClose" class="close-modal-btn" (click)="close.emit()">
          <svg class="close-modal-icon">
            <use spriteVersion iconName="modal-close"></use>
          </svg>
        </button>

        <div class="email-notif-settings-inner-wrapper">
          <p class="form-modal-title">Email Notifications</p>

          <ul class="email-notif-switcher-list">
            <li class="email-notif-switcher-item">
              <ng-toggle
                [value]="isSelectAllNotifications"
                [color]="{unchecked: '#D8D8D8', checked: '#37A702'}"
                (change)="switchNotifications('all')"
              ></ng-toggle>

              All notifications
            </li>
            <li class="email-notif-switcher-item" *ngIf="currentUser.systemRole !== SystemRole.ROLE_COMPANY_ADMIN">
              <ng-toggle
                [value]="notificationSettings.colorBoards"
                [color]="{unchecked: '#D8D8D8', checked: '#37A702'}"
                (change)="switchNotifications('colorBoards')"
              ></ng-toggle>

              <p class="email-notif-text">
                Color Boards
                <span class="email-notif-desc">When you are added to a new color board</span>
              </p>
            </li>

            <li class="email-notif-switcher-item" *ngIf="currentUser.systemRole !== SystemRole.ROLE_COMPANY_ADMIN">
              <ng-toggle
                [value]="notificationSettings.projects"
                [color]="{unchecked: '#D8D8D8', checked: '#37A702'}"
                (change)="switchNotifications('projects')"
              ></ng-toggle>

              <p class="email-notif-text">
                Projects
                <span class="email-notif-desc">When you are added to a new project</span>
              </p>
            </li>

            <li class="email-notif-switcher-item">
              <ng-toggle
                [value]="notificationSettings.areaComments"
                [color]="{unchecked: '#D8D8D8', checked: '#37A702'}"
                (change)="switchNotifications('areaComments')"
              ></ng-toggle>

              <p class="email-notif-text">
                Area Comments
                <span class="email-notif-desc">When you are mentioned in an area comment</span>
              </p>
            </li>

            <li class="email-notif-switcher-item">
              <ng-toggle
                [value]="notificationSettings.noteComments"
                [color]="{unchecked: '#D8D8D8', checked: '#37A702'}"
                (change)="switchNotifications('noteComments')"
              ></ng-toggle>

              <p class="email-notif-text">
                Note Comments
                <span class="email-notif-desc">When you are mentioned in a note comment</span>
              </p>
            </li>

            <li class="email-notif-switcher-item email-notif-switcher-item__task">
              <div class="email-notif-switcher-item-inner-wrapper">
                <ng-toggle
                  [value]="isSelectOneOfTasksNotifications"
                  [color]="{unchecked: '#D8D8D8', checked: '#37A702'}"
                  (change)="switchNotifications('tasks')"
                ></ng-toggle>

                <p class="email-notif-text">
                  Tasks
                </p>
              </div>

              <ul class="task-notif--sub-switcher-list">
                <li class="task-notif--sub-switcher-list__item">
                  <ng-toggle
                    [value]="notificationSettings.newTask"
                    [color]="{unchecked: '#D8D8D8', checked: '#37A702'}"
                    (change)="switchTasksNotifications('newTask')"
                  ></ng-toggle>

                  <p class="email-notif-text">
                    New Tasks
                    <span class="email-notif-desc">When you are assigned a new task</span>
                  </p>
                </li>

                <li class="task-notif--sub-switcher-list__item">
                  <ng-toggle
                    [value]="notificationSettings.completedTask"
                    [color]="{unchecked: '#D8D8D8', checked: '#37A702'}"
                    (change)="switchTasksNotifications('completedTask')"
                  ></ng-toggle>

                  <p class="email-notif-text">
                    Completed Task
                    <span class="email-notif-desc">When a task you created has been completed</span>
                  </p>
                </li>

                <li class="task-notif--sub-switcher-list__item task-notif--sub-switcher-list__item--reminder">
                  <div class="task-notif--sub-switcher-list__item--reminder-inner-wrapper">
                    <ng-toggle
                      [value]="notificationSettings.taskReminder"
                      [color]="{unchecked: '#D8D8D8', checked: '#37A702'}"
                      (change)="switchTasksNotifications('taskReminder')"
                    ></ng-toggle>

                    <p class="email-notif-text">
                      Task Reminder
                      <span class="email-notif-desc">Daily reminder to complete task</span>
                    </p>
                  </div>
                  <app-simple-selector [optionsMap]="taskReminderMap"
                                       [selectedOption]="notificationSettings.taskReminderPeriod" (changeEl)="changeTaskReminderPeriod($event)">
                  </app-simple-selector>

                </li>
              </ul>
            </li>
          </ul>

        </div>


        <div class="form-btn-wrapper">
          <button type="button" class="btn-cancel" id="btnCancel" (click)="close.emit()">Cancel</button>
          <button type="submit" loader="white" [disabled]="submitBtnDisabled" [showLoader]="submitBtnDisabled" (click)="submit()" class="btn-invite" id="btnSave">Save</button>
        </div>
      </div>
    </ng-scrollbar>
</div>
