import {EventEmitter, Injectable, OnDestroy, Output} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {apiUrl, webSocketUrl} from "../../environments/environment";
import {Frame} from "stompjs";
import {Notification} from "../_models/notification";
import {Observable, Subject, takeUntil} from "rxjs";
import SockJS from "sockjs-client";
import * as Stomp from "stompjs";
import {User} from "../_models/user";
import {AuthService} from "./auth.service";
import {GlobalStore} from "../global.store";
import {CHANGES_SAVED_MESSAGE} from "../config/constants";

@Injectable({
  providedIn: 'root'
})
export class NotificationService implements OnDestroy {
  private readonly destroy$ = new Subject<void>();
  @Output() newConnectionStatus: EventEmitter<boolean> = new EventEmitter<boolean>();

  showNotification: EventEmitter<any> = new EventEmitter<any>();
  notificationsList: Notification[] = [];
  unreadNotificationsList: Notification[] = [];
  socket: WebSocket;
  stompClient: any;
  unreadNotifCount: number = 0
  reconnectDelay: number = 3;
  reconnectAttempt: number = 0;

  pingInterval: any;
  pingTimeout: any;
  connectToWebsocketTimeout: any;

  user: User;

  constructor(readonly globalStore: GlobalStore,
              private authService: AuthService,
              private http: HttpClient) {
    this.globalStore.currentUser$.pipe(takeUntil(this.destroy$)).subscribe((user) => this.user = user);
  }

  errorNotification(msg: string) {
    this.showNotification.emit({msg: msg, type: 'error'});
  }

  successNotification(msg: string = CHANGES_SAVED_MESSAGE) {
    this.showNotification.emit({msg: msg, type: 'success'});
  }


  getNotifications(lastNotifId: number) {
    let params = new HttpParams().append('lastNotifId', lastNotifId)
    return this.http.get<Notification[]>(`${apiUrl}/notifications`, {params: params, withCredentials: true})
  }

  onConnected = (userId: number) => {
    this.reconnectAttempt = 0;
    this.newConnectionStatus.emit(true);
    if (this.connectToWebsocketTimeout) clearTimeout(this.connectToWebsocketTimeout)

    this.stompClient.subscribe("/user/" + userId + "/notifications", this.onMessageReceived.bind(this));
    this.stompClient.subscribe("/user/" + userId + "/forceLogout", this.onForceLogout.bind(this));
    this.stompClient.subscribe("/user/" + userId + "/queue/pong", this.onPong.bind(this));
  }

  onMessageReceived(msg: Frame) {
    let isArray = Array.isArray(JSON.parse(msg.body))
    if(isArray) {
      const notification = JSON.parse(msg.body) as Notification[];
      this.notificationsList = [];
      this.notificationsList.push(...notification);
      this.unreadNotificationsList = this.notificationsList.filter(notif => notif.delivered === false)
      return
    } else {
      this.notificationsList.unshift(JSON.parse(msg.body))
      this.unreadNotificationsList = this.notificationsList.filter(notif => notif.delivered === false)
      this.unreadNotifCount += 1;
    }
  }

  onForceLogout(msg: Frame) {
    this.authService.logout();
  }

  sendPing(userId: number) {
    this.stompClient.send("/app/" + userId + "/ping", {}, "");

    this.pingTimeout = setTimeout(() => {
      this.handlePingTimeout();
    }, 3000);
  }

  handlePingTimeout() {
    this.newConnectionStatus.emit(false);
    this.reconnect();
  }

  reconnect() {
    if (this.pingInterval) {
      clearInterval(this.pingInterval);
    }
    this.reconnectAttempt++;

    if (this.connectToWebsocketTimeout) clearTimeout(this.connectToWebsocketTimeout)
    this.connectToWebsocketTimeout = setTimeout(() => {
      this.connectToWebsocket();
    }, this.reconnectDelay * 1000);
  }

  onPong(msg: Frame) {
    if (this.pingTimeout) clearTimeout(this.pingTimeout);
  }

  notificationDelivered(notifId: number, userId: number) {
    this.stompClient.send(`/app/${userId}/notifications/delivered`, {}, notifId);
  }

  getCountUnreadNotifications(): Observable<number> {
    return this.http.get<number>(`${apiUrl}/notifications/unreadCount`, {withCredentials: true})
  }

  connectToWebsocket() {
    if (this.user) {
      this.socket = new SockJS(webSocketUrl);
      this.stompClient = Stomp.over(this.socket);
      this.stompClient.debug = null;
      this.stompClient.connect({}, this.onConnected.bind(this, this.user.id), this.onError.bind(this));
    }
  }

  onError(error: any) {
    console.error('WebSocket error occurred', error);
    this.reconnect()
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }
}
