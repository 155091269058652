import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Project} from "../_models/project";
import {apiUrl} from "../../environments/environment";
import {NotificationSettings} from "../_models/notificationSettings";

@Injectable({
  providedIn: 'root'
})
export class NotificationSettingsService {

  constructor(private http: HttpClient) { }

  getNotificationSettings(): Observable<NotificationSettings> {
    return this.http.get<NotificationSettings>(`${apiUrl}/emailNotificationSettings`, {withCredentials: true})
  }

  updateNotificationSettings(notificationSettings: NotificationSettings) {
    return this.http.put<NotificationSettings>(`${apiUrl}/emailNotificationSettings`, notificationSettings, {withCredentials: true})
  }
}
